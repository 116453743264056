/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('./src/styles/main.css');

exports.shouldUpdateScroll = ({ routerProps: { location } }) => !location.state?.preventScroll;

exports.onRouteUpdate = ({}) => {
  try {
    window.HubSpotConversations.widget.refresh();
  } catch (e) {
    console.warn('Could not refresh Hubspot Conversations widget');
  }
};

// This fixes a problem where the /docs/* pages redirects to a link with a trailing slash
exports.onInitialClientRender = () => {
  const handleBodyClick = (event) => {
    const targetLink = event.target.closest('a');

    if (targetLink && targetLink.origin === window.location.origin) {
      const path = targetLink.getAttribute('href');

      if (path.includes('/docs/')) {
        event.preventDefault();
        window.location.href = path;
      }
    }
  };

  document.body.addEventListener('click', handleBodyClick);
};
